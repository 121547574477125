@mixin w {
  @media only screen and (max-width: "1599px") {
    @content; } }
@mixin x {
  @media only screen and (max-width: "1439px") {
    @content; } }
@mixin d {
  @media only screen and (max-width: "1229px") {
    @content; } }
@mixin t {
  @media only screen and (max-width: "1023px") {
    @content; } }
@mixin m {
  @media only screen and (max-width: "767px") {
    @content; } }
@mixin a {
  @media only screen and (max-width: "639px") {
    @content; } }
@mixin s {
  @media only screen and (max-width: "479px") {
    @content; } }

@font-face {
  font-family: 'FuturaPT';
  src: url('fonts/FuturaPT-Book.woff') format('woff'), url('fonts/fonts/FuturaPT-Book.woff2') format('woff2');
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'FuturaPT';
  src: url('fonts/FuturaPT-Medium.woff') format('woff'), url('fonts/fonts/FuturaPT-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'FuturaPT';
  src: url('fonts/FuturaPT-Demi.woff') format('woff'), url('fonts/fonts/FuturaPT-Demi.woff2') format('woff2');
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'FuturaPT';
  src: url('fonts/FuturaPT-Heavy.woff') format('woff'), url('fonts/fonts/FuturaPT-Heavy.woff2') format('woff2');
  font-weight: 800;
  font-style: normal; }

$bl: #000;
$white: #fff;
$purple: #646EE3;
$l-purple: #B9BEF5;

html {
  height: 100%;
  &.no-scroll {
    height: 100%;
    overflow: hidden; } }

body {
  min-width: 375px;
  height: 100%;
  font-family: 'FuturaPT', serif;
  font-size: 18px;
  line-height: 1.3;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $bl;
  &.no-scroll {
    overflow: hidden; } }

.out {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  overflow: hidden; }

.wrapper {
  flex-grow: 1; }

.center {
  max-width: 1230px;
  margin: 0 auto;
  padding: 0 30px;
  @include t {
    padding: 0 20px; }
  @include m {
    padding: 0 15px; } }

button,
input,
textarea,
select {
  font-family: 'FuturaPT', serif; }

.header {
  position: relative;
  z-index: 10;
  background: $purple;
  padding: 23px 0;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  @include d {
    padding: 18px 0; }
  @include t {
    padding: 15px 0; }
  @include m {
    padding: 12px 0; }
  &__center {
    display: flex;
    align-items: center; }
  &__logo {
    margin-right: 40px;
    font-size: 0;
    @include t {
      margin-right: 30px; }
    @include m {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%); } }
  &__logo &__pic {
    width: 130px;
    @include m {
      width: 120px; } }
  &__nav {
    display: flex;
    margin-right: auto;
    padding-top: 4px;
    @include m {
      padding: 0; } }
  &__item {
    position: relative;
    @include m {
      position: static; }
    @include nl {
      margin-right: 35px;
      @include t {
        margin-right: 30px; }
      @include m {
        margin: 0; } }
    &:nth-child(2) {
      @include m {
        display: none; } } }
  &__head--no-arrow {
    position: relative;
    padding-right: 20px;
    font-size: 24px;
    color: $l-purple;
    cursor: pointer;
    transition: all .3s;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    @include d {
      font-size: 20px; }
    @include m {
      display: none; }
    &:hover {
      color: $white; } }
  &__head {
    position: relative;
    padding-right: 20px;
    font-size: 24px;
    color: $l-purple;
    cursor: pointer;
    transition: all .3s;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    @include d {
      font-size: 20px; }
    @include m {
      display: none; }
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-25%);
      @include arr(10,6,$l-purple,b);
      transition: all .3s; }
    &:hover {
      color: $white;
      &:before {
        border-color: $white transparent transparent transparent; } } }
  &__item.open &__head:before {
    transform: translateY(-25%) rotate(180deg); }
  &__body {
    position: absolute;
    top: calc(100% + 40px);
    left: -6px;
    display: flex;
    flex-direction: column;
    width: 320px;
    border-radius: 15px;
    background: #5C64D3;
    visibility: hidden;
    opacity: 0;
    transition: all .3s;
    @include d {
      top: calc(100% + 33px);
      width: 280px; }
    @include t {
      top: calc(100% + 30px);
      width: 240px;
      border-radius: 10px; }
    @include m {
      top: 100%;
      left: 0;
      right: 0;
      width: 100%;
      border-radius: 0 0 10px 10px;
      visibility: visible;
      opacity: 1;
      padding: 5px 0;
      transform: translateX(-100%);
      &.visible {
        transform: translateX(0); } } }
  &__item.open &__body {
    visibility: visible;
    opacity: 1; }
  &__box {
    position: relative; }
  &__box:not(:last-child) &__top {
    border-bottom: 1px solid rgba($white, .3); }
  &__top {
    position: relative;
    display: flex;
    align-items: center;
    height: 50px;
    padding-left: 25px;
    font-size: 24px;
    font-weight: 500;
    color: $white;
    transition: background .3s;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    @include d {
      height: 40px;
      font-size: 20px; }
    @include t {
      padding-left: 20px;
      font-size: 18px; }
    @include m {
      font-size: 20px; }
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      right: 25px;
      transform: translateY(-50%);
      @include arr(6,10,$white,r);
      @include t {
        top: 55%;
        right: 10px; }
      @include m {
        right: 20px;
        transform: translateY(-50%) rotate(90deg);
        transition: transform .3s; } }
    &:hover {
      background: rgba($white, .1);
      @include m {
        background: none; } } }
  &__box:first-child &__top {
    border-radius: 15px 15px 0 0;
    @include t {
      border-radius: 10px 10px 0 0; }
    @include m {
      border-radius: 0; }
    &:before {
      top: 55%;
      @include m {
        top: 50%; } } }
  &__box:last-child &__top {
    border-radius: 0 0 15px 15px;
    padding-bottom: 3px;
    @include t {
      border-radius: 0 0 10px 10px; }
    @include m {
      border-radius: 0; }
    &:before {
      @include d {
        top: 47%; }
      @include m {
        top: 50%; } } }
  &__box.open &__top {
    background: rgba($white, .3);
    &:before {
      @include m {
        transform: translateY(-50%) rotate(-90deg); } } }
  &__list {
    position: absolute;
    top: 0;
    left: calc(100% + 15px);
    display: flex;
    flex-direction: column;
    width: 320px;
    border-radius: 15px;
    background: #5C64D3;
    visibility: hidden;
    opacity: 0;
    transition: all .3s;
    @include d {
      left: calc(100% + 10px);
      width: 280px; }
    @include t {
      width: 240px;
      border-radius: 10px; }
    @include m {
      display: none;
      position: static;
      width: 100%;
      padding: 0;
      visibility: visible;
      opacity: 1;
      transition: none; }
    @media only screen and (min-width: "767px") {
      display: block!important; } }
  &__box.open &__list {
    visibility: visible;
    opacity: 1; }
  &__link {
    display: flex;
    align-items: center;
    height: 50px;
    padding: 0 15px 0 25px;
    font-size: 24px;
    color: #ACAFE9;
    transition: color .3s;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    @include d {
      height: 40px;
      font-size: 20px; }
    @include t {
      padding-left: 20px;
      font-size: 18px; }
    @include m {
      border-bottom: 1px solid rgba($white, .3);
      font-size: 20px; }
    &:hover {
      color: $white; }
    @include nl {
      border-bottom: 1px solid rgba($white, .3); } }
  &__box:last-child &__link:last-child {
    border: none; }
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 26px;
    width: 26px;
    height: 26px;
    margin-right: 15px;
    font-size: 0; }
  &__icon &__pic {
    max-width: 100%;
    max-height: 100%; }
  &__text {
    font-size: 24px;
    color: #ACAFE9;
    transition: color .3s;
    @include d {
      font-size: 20px; }
    @include t {
      font-size: 18px; } }
  &__top &__text {
    color: $white; }
  &__link:hover &__text {
    color: $white; }
  &__search {
    position: relative;
    flex-shrink: 0;
    width: 480px;
    @include d {
      width: 430px; }
    @include t {
      width: 300px; }
    @include m {
      width: 40px;
      transition: width .3s; }
    &.show {
      @include m {
        width: 450px; }
      @include a {
        width: 290px; } } }
  &__search &__input {
    width: 100%;
    height: 49px;
    padding: 0 70px 0 35px;
    border-radius: 25px;
    background: #5C64D3;
    font-size: 18px;
    font-weight: 500;
    color: $white;
    @include d {
      height: 40px;
      border-radius: 20px;
      padding: 0 60px 0 25px; }
    @include m {
      padding: 0;
      transition: padding .3s;
      font-size: 16px; }
    @include placeholder {
      color: rgba($white, .3); } }
  &__search.show &__input {
    @include m {
      padding: 0 50px 0 20px; } }
  &__search &__btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 24px;
    width: 37px;
    height: 37px;
    font-size: 0;
    @include d {
      right: 15px; }
    @include m {
      display: none; }
    .icon {
      font-size: 24px;
      fill: $white;
      transition: fill .3s; }
    &:hover .icon {
      fill: rgba($white, .7); } }
  &__open {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 40px;
    border-radius: 50%;
    background: #5C64D3;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    font-size: 0;
    @include m {
      display: inline-block; }
    .icon {
      font-size: 16px;
      fill: $white; } } }

.burger {
  position: relative;
  z-index: 30;
  display: none;
  width: 39px;
  height: 32px;
  border-radius: 4px;
  background: #5C64D3;
  text-align: center;
  transition: background .3s;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  @include m {
    display: inline-block; }
  &:before,
  &:after,
  span {
    display: block;
    width: 25px;
    height: 2px;
    background: $white;
    border-radius: 2px;
    transition: transform .3s, width .3s, background .3s; }
  &:before,
  &:after {
    content: '';
    margin: 0 auto; }
  span {
    margin: 5px auto; }
  &.active {
    &:before {
      transform: translateY(7px) rotate(45deg); }
    span {
      transform: rotate(-45deg); }
    &:after {
      transform: translateY(-7px) rotate(45deg); } } }

.books {
  padding: 45px 0 55px;
  @include d {
    padding: 35px 0 50px; }
  @include m {
    padding: 25px 0 45px; }
  &__title {
    display: none;
    @include m {
      display: block;
      margin-bottom: 20px; } }
  &__head {
    display: flex;
    margin-bottom: 20px;
    padding-left: 25px;
    font-size: 24px;
    @include d {
      margin-bottom: 15px;
      padding-left: 15px;
      font-size: 20px; }
    @include m {
      display: none; } }
  &__container {
    padding: 15px 0 10px 20px;
    border-radius: 15px;
    border: 2px solid #DBDBE8;
    @include d {
      padding: 10px 0 10px 15px; }
    @include t {
      border-width: 1px; }
    @include m {
      padding: 0;
      border: none; } }
  &__line {
    display: flex;
    align-items: center;
    @include m {
      display: block;
      overflow: hidden;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
      border-radius: 5px; }
    @include nl {
      margin-bottom: 18px;
      @include d {
        margin-bottom: 10px; } } }
  &__top {
    display: flex;
    align-items: center;
    flex: 0 0 365px;
    width: 365px;
    @include d {
      flex: 0 0 280px;
      width: 280px; }
    @include t {
      flex: 0 0 250px;
      width: 250px; }
    @include m {
      position: relative;
      width: 100%;
      padding: 10px 15px;
      -webkit-tap-highlight-color: rgba(0,0,0,0);
      &:before {
        content: "";
        position: absolute;
        top: 54%;
        right: 15px;
        transform: translateY(-50%) rotate(180deg);
        @include arr(10,6,#5C64D3,b);
        transition: transform .3s; } } }
  &__desc {
    width: 100%;
    display: flex;
    align-items: center;
    @include m {
      pointer-events: none; } }
  &__line.active &__top:before {
    @include m {
      transform: translateY(-50%) rotate(0deg); } }
  &__line.active &__list {
    @include m {
      height: 0px;
      transition: all .3s; } }
  &__list {
    display: flex;
    flex-grow: 1;
    @include m {
      height: 46px;
      overflow: hidden;
      transition: all .3s; } }
  &__head &__desc {
    color: #D1D1D9; }
  &__head &__list &__item {
    color: #DBDBE8;
    transition: color .3s;
    &:hover {
      color: darken(#DBDBE8, 10); } }
  &__item {
    flex: 0 0 calc(100%/11);
    width: calc(100%/11);
    text-align: center;
    transition: background .3s; }
  &__line &__item {
    position: relative;
    height: 46px;
    border-left: 2px solid #DBDBE8;
    font-size: 0;
    @include d {
      height: 40px; }
    @include t {
      border-width: 1px; }
    @include m {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 46px;
      background: #D1D1D9;
      font-size: 18px;
      font-weight: 500;
      color: $white; }
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 25px;
      height: 25px;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAyNSAyNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBvcGFjaXR5PSIwLjUiIGNsaXAtcGF0aD0idXJsKCNjbGlwMCkiPjxwYXRoIGQ9Ik0xNi43MzMzIDEyLjUwMDRMMjQuMTIzMiA1LjExMTAyQzI1LjI5MjUgMy45NDE3MyAyNS4yOTI1IDIuMDQ2MTkgMjQuMTIzMiAwLjg3NzQ5N0MyMi45NTUxIC0wLjI5MTE5NyAyMS4wNTg0IC0wLjI5MTE5NyAxOS44OTAzIDAuODc3NDk3TDEyLjUwMDMgOC4yNjgwNUw1LjExMDM4IDAuODc3NDk3QzMuOTQyMjkgLTAuMjkxMTk3IDIuMDQ1NTUgLTAuMjkxMTk3IDAuODc3NDU4IDAuODc3NDk3Qy0wLjI5MTgzNSAyLjA0Njc5IC0wLjI5MTgzNSAzLjk0MjMzIDAuODc3NDU4IDUuMTExMDJMOC4yNjY4MiAxMi41MDA0TDAuODc3NDU4IDE5Ljg4OTdDLTAuMjkxODM1IDIxLjA1OSAtMC4yOTE4MzUgMjIuOTU0NiAwLjg3NzQ1OCAyNC4xMjMzQzEuNDYxMjEgMjQuNzA4MiAyLjIyODE2IDI0Ljk5OTggMi45OTM5MiAyNC45OTk4QzMuNzU5MDggMjQuOTk5OCA0LjUyNjYzIDI0LjcwODIgNS4xMTAzOCAyNC4xMjMzTDEyLjUwMDMgMTYuNzMzOUwxOS44OTAzIDI0LjEyMzlDMjAuNDc0NiAyNC43MDg4IDIxLjI0MSAyNS4wMDA0IDIyLjAwNjggMjUuMDAwNEMyMi43NzI1IDI1LjAwMDQgMjMuNTM5NSAyNC43MDg4IDI0LjEyMzIgMjQuMTIzOUMyNS4yOTI1IDIyLjk1NDYgMjUuMjkyNSAyMS4wNTkgMjQuMTIzMiAxOS44OTAzTDE2LjczMzMgMTIuNTAwNFoiIGZpbGw9IiNEQkRCRTgiLz48L2c+PGRlZnM+PGNsaXBQYXRoIGlkPSJjbGlwMCI+PHJlY3Qgd2lkdGg9IjI1IiBoZWlnaHQ9IjI1IiBmaWxsPSJ3aGl0ZSIvPjwvY2xpcFBhdGg+PC9kZWZzPjwvc3ZnPg==);
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: 100% 100%;
      transition: transform .3s;
      @include d {
        width: 20px;
        height: 20px; }
      @include m {
        display: none; } } }
  &__line a.books__item {
    @include m {
      background: $l-purple; }
    &:before {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAyNSAyNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTEuMzE2NSAyMC45NjkzQzEwLjE4MyAyMi4xMDI3IDguMzQzOTEgMjIuMTAyNyA3LjIxMSAyMC45NjkzTDAuODUwMDkxIDE0LjYwODJDLTAuMjgzMzY0IDEzLjQ3NTMgLTAuMjgzMzY0IDExLjYzNjEgMC44NTAwOTEgMTAuNTAzMkMxLjk4MyA5LjM2OTcgMy44MjIxMyA5LjM2OTcgNC45NTU1OCAxMC41MDMyTDguNzQ1NDMgMTQuMjkyNkM5LjAzMTUzIDE0LjU3ODEgOS40OTU5NiAxNC41NzgxIDkuNzgyNjEgMTQuMjkyNkwyMC4wNDQ0IDQuMDMwNTNDMjEuMTc3MyAyLjg5NzA1IDIzLjAxNjUgMi44OTcwNSAyNC4xNDk5IDQuMDMwNTNDMjQuNjk0MiA0LjU3NDg0IDI1IDUuMzEzMzUgMjUgNi4wODMwNUMyNSA2Ljg1Mjc0IDI0LjY5NDIgNy41OTEyNSAyNC4xNDk5IDguMTM1NTZMMTEuMzE2NSAyMC45NjkzWiIgZmlsbD0iIzVDNjREMyIvPjwvc3ZnPg==); }
    &:hover:before {
      transform: translate(-50%,-50%) scale(1.15); } }
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 30px;
    width: 30px;
    height: 30px;
    margin-right: 26px;
    font-size: 0;
    @include d {
      margin-right: 15px; }
    @include m {
      width: 25px;
      height: 25px;
      margin-right: 15px; } }
  &__icon &__pic {
    width: 100%;
    max-width: 100%;
    max-height: 100%; }
  &__text {
    font-size: 30px;
    color: $bl;
    transition: color .3s;
    @include d {
      font-size: 24px; }
    @include t {
      font-size: 20px; }
    @include m {
      font-size: 22px; } }
  &__desc:hover &__text {
    color: #5C64D3;
    @include m {
      color: $bl; } } }

.title {
  position: relative;
  padding-left: 35px;
  font-size: 36px;
  line-height: 1.1;
  font-weight: 800;
  @include d {
    font-size: 30px; }
  @include m {
    padding-left: 25px;
    font-size: 24px; }
  &:before {
    content: "";
    position: absolute;
    top: 12px;
    left: 0;
    width: 19px;
    height: 16px;
    background: $purple;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 6px 50%);
    @include d {
      top: 9px; }
    @include m {
      top: 7px;
      width: 16px;
      height: 13px; } } }

.book {
  display: flex;
  align-items: center;
  padding: 7px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  background: $white;
  transition: box-shadow .3s;
  @include m {
    padding: 5px; }
  &_big {
    padding: 15px;
    @include m {
      padding: 10px; }
    @include a {
      padding: 5px; } }
  &__preview {
    flex: 0 0 130px;
    width: 130px;
    font-size: 0; }
  &_big &__preview {
    flex: 0 0 150px;
    width: 150px;
    @include a {
      flex: 0 0 130px;
      width: 130px; } }
  &__pic {
    width: 100%; }
  &__desc {
    flex-grow: 1;
    padding-left: 15px;
    color: $bl; }
  &_big &__desc {
    padding-left: 50px;
    @include t {
      padding-left: 30px; }
    @include m {
      padding-left: 20px; }
    @include a {
      padding-left: 15px; } }
  &__name {
    margin-bottom: 10px;
    font-size: 24px;
    line-height: 1.2;
    font-weight: 800;
    transition: color .3s;
    @include m {
      margin-bottom: 5px;
      font-size: 20px; }
    @include a {
      font-size: 22px; } }
  &_big &__name {
    font-size: 30px;
    @include m {
      font-size: 28px; }
    @include a {
      font-size: 22px;
      line-height: 1.15; } }
  &__authors {
    font-weight: 400;
    @include m {
      font-size: 16px; } }
  &_big &__authors {
    font-size: 22px;
    line-height: 1.5;
    @include m {
      font-size: 20px;
      line-height: 1.4; }
    @include a {
      font-size: 16px;
      line-height: 1.3; } }
  &__category {
    font-weight: 600; } }

a.book:hover {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15); }
a.book:hover .book__name {
   color: darken(#5C64D3, 20); }

.popular {
  &__title {
    margin-bottom: 25px;
    @include m {
      margin-bottom: 20px; } }
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    @include m {
      margin: 0 -5px; }
    @include a {
      display: block;
      margin: 0; } }
  .book {
    flex: 0 0 calc(33.33% - 20px);
    width: calc(33.33% - 20px);
    margin: 0 10px 30px;
    @include d {
      flex: 0 0 calc(50% - 20px);
      width: calc(50% - 20px);
      margin-bottom: 20px; }
    @include m {
      flex: 0 0 calc(50% - 10px);
      width: calc(50% - 10px);
      margin: 0 5px 15px; }
    @include a {
      width: 100%;
      margin: 0; }
    @include nl {
      @include a {
        margin-bottom: 15px; } } }
  &_section {
    margin-bottom: 30px;
    @include a {
      margin-bottom: 45px; } } }

.about {
  margin-bottom: 60px;
  @include m {
    margin-bottom: 50px; }
  &__title {
    margin-bottom: 20px;
    @include m {
      margin-bottom: 17px; } }
  &__text {
    line-height: (30/18);
    @include d {
      line-height: 1.5; }
    @include m {
      line-height: 1.3; } } }

.footer {
  margin-top: 45px;
  padding: 45px 0;
  background: #151418;
  @include d {
    padding: 30px 0; }
  @include m {
    margin-top: 50px;
    padding: 25px 0; }
  &__center {
    position: relative;
    @include m {
      text-align: center; } }
  &__copyright {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    color: #908C9B;
    @include m {
      position: static;
      transform: translate(0,0);
      margin-bottom: 15px;
      font-size: 14px; } }
  &__menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @include m {
      align-items: center; } }
  &__link {
    color: $white;
    transition: color .3s;
    &:hover {
      color: rgba($white, .8); }
    @include nl {
      margin-bottom: 8px; } } }

.breadcrumbs {
  &__list {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  &__item {
    position: relative;
    display: inline;
    font-size: 18px;
    color: #D1D1D9;
    &:not(:last-child) {
      margin-right: 10px;
      padding-right: 20px;
      &:after {
        content: "";
        position: absolute;
        top: 60%;
        right: 0;
        transform: translateY(-50%);
        @include arr(4,6,#D1D1D9,r); } } }
  &__link {
    color: #D1D1D9;
    transition: color .3s;
    &:hover {
      color: $purple; } } }

.section {
  margin-bottom: 65px;
  padding-top: 50px;
  @include d {
    padding-top: 40px; }
  @include t {
    padding-top: 30px; }
  @include m {
    margin-bottom: 45px;
    padding-top: 25px; }
  &__breadcrumbs {
    margin-bottom: 15px;
    padding-left: 50px;
    @include m {
      padding-left: 0; } }
  &__title {
    margin: 0 0 35px 12px;
    @include t {
      margin-bottom: 25px; }
    @include m {
      margin: 0 0 20px; } }
  &__back {
    display: inline-block;
    position: relative;
    margin: 0 0 15px 50px;
    padding-left: 15px;
    color: #D1D1D9;
    transition: color .3s;
    @include m {
      margin-left: 0; }
    &:before {
      content: "";
      position: absolute;
      top: 55%;
      left: 0;
      transform: translateY(-50%);
      @include arr(6,8,#D1D1D9,l);
      transition: border-color .3s; }
    &:hover {
      color: $purple;
      &:before {
        border-color: transparent $purple transparent transparent; } } }
  &__objects {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px 25px;
    @include m {
      margin-bottom: 20px; }
    @include a {
      margin: 0 -4px 20px; } }
  &__object {
    display: flex;
    align-items: center;
    flex: 0 0 calc(20% - 10px);
    width: calc(20% - 10px);
    height: 40px;
    padding: 0 13px;
    margin: 0 5px 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    color: $bl;
    transition: background .3s;
    @include d {
      flex: 0 0 calc(25% - 10px);
      width: calc(25% - 10px);
      font-size: 16px; }
    @include t {
      flex: 0 0 calc(33.33% - 10px);
      width: calc(33.33% - 10px);
      padding: 0 10px; }
    @include a {
      flex: 0 0 calc(50% - 8px);
      width: calc(50% - 8px);
      height: 35px;
      margin: 0 4px 8px;
      padding: 0 7px;
      font-size: 14px;
      line-height: 1.1; }
    &:hover {
      background: $l-purple; } }
  &__object &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 22px;
    height: 22px;
    margin-right: 15px;
    font-size: 0;
    @include a {
      width: 20px;
      margin-right: 7px; } }
  &__object &__pic {
    max-width: 100%;
    max-height: 100%; }
  &__top {
    margin-bottom: 30px;
    text-align: center;
    @include m {
      margin-bottom: 20px; } }
  &__stage {
    display: inline-block;
    min-width: 240px;
    padding: 8px 20px;
    background: $purple;
    border-radius: 24px;
    font-size: 24px;
    color: $white;
    @include m {
      min-width: auto;
      padding: 5px 25px;
      border-radius: 18px;
      font-size: 20px; } }
  &__box {
    @include nl {
      @include d {
        margin-bottom: 20px; }
      @include a {
        margin-bottom: 30px; } } }
  &__classes {
    display: flex;
    margin: 0 -5px 35px;
    @include d {
      flex-wrap: wrap;
      margin-bottom: 25px; }
    @include m {
      margin-bottom: 20px; }
    @include a {
      margin: 0 -4px 20px; } }
  &__class {
    flex: 0 0 calc(calc(100%/11) - 10px);
    width: calc(calc(100%/11) - 10px);
    margin: 0 5px;
    padding: 5px;
    border-radius: 17px;
    border: 2px solid #D1D1D9;
    text-align: center;
    color: #D6D6DE;
    transition: all .3s;
    @include d {
      flex: 0 0 calc(calc(100%/6) - 10px);
      width: calc(calc(100%/6) - 10px);
      margin-bottom: 10px; }
    @include a {
      flex: 0 0 calc(calc(100%/4) - 8px);
      width: calc(calc(100%/4) - 8px);
      margin: 0 4px 8px;
      padding: 3px 5px;
      font-size: 16px; }
    &:hover {
      border-color: $purple;
      color: $purple; } }
  .about {
    max-width: 900px;
    margin-top: 20px;
    @include a {
      margin-top: 40px; } } }

.search {
  &__wrap {
    position: relative; }
  &__input {
    width: 100%;
    height: 70px;
    padding: 0 80px 0 40px;
    border-radius: 35px;
    border: 1px solid #D1D1D9;
    font-size: 24px;
    font-weight: 600;
    color: $bl;
    @include t {
      height: 60px;
      padding-left: 25px; }
    @include m {
      height: 45px;
      padding: 0 55px 0 15px;
      border-radius: 22px;
      font-size: 18px; }
    @include placeholder {
      color: #D1D1D9; } }
  &__btn {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 70px;
    border-radius: 50%;
    background: #5C64D3;
    font-size: 0;
    transition: background .3s;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    @include t {
      width: 60px; }
    @include m {
      width: 45px; }
    .icon {
      font-size: 28px;
      fill: $white;
      @include m {
        font-size: 20px; } }
    &:hover {
      background: lighten(#5C64D3, 5); } } }

.about__center {
  p {
    margin-top: 15px; }
  ul {
    list-style-type: disc;
    list-style-position: inside; }
  ol {
    list-style-type: decimal;
    list-style-position: inside; }
  ul ul, ol ul {
    list-style-type: circle;
    list-style-position: inside;
    margin-left: 15px; }
  ol ol, ul ol {
    list-style-type: lower-latin;
    list-style-position: inside;
    margin-left: 15px; } }

.desc {
  .book {
    margin-bottom: 15px; }
  &__content {
    margin-bottom: 25px;
    line-height: 1.5;
    p {
      margin-top: 15px; }
    ul {
      list-style-type: disc;
      list-style-position: inside; }
    ol {
      list-style-type: decimal;
      list-style-position: inside; }
    ul ul, ol ul {
      list-style-type: circle;
      list-style-position: inside;
      margin-left: 15px; }
    ol ol, ul ol {
      list-style-type: lower-latin;
      list-style-position: inside;
      margin-left: 15px; }
    @include m {
      margin-bottom: 20px;
      line-height: 1.4; }
    @include a {
      font-size: 16px; } }
  .search {
    margin-bottom: 15px; }
  .accord {
    margin-bottom: 35px;
    @include m {
      margin-bottom: 25px; } }
  .share {
    margin-bottom: 25px; }
  &__info {
    margin: 30px 0;
    text-align: center;
    font-size: 36px;
    font-weight: 800;
    @include t {
      font-size: 30px; }
    @include m {
      margin: 20px 0;
      font-size: 24px; } }
  &__zoom {
    display: block;
    position: relative;
    margin-bottom: 35px;
    padding: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    text-align: center;
    font-size: 0;
    @include t {
      margin-bottom: 25px; }
    @include m {
      margin-bottom: 15px;
      padding: 2px; } }
  &__zoom &__pic {
    max-width: 100%; }
  &__center {
    margin-bottom: 40px;
    text-align: center;
    @include m {
      margin-bottom: 20px; } }
  &__control {
    position: relative;
    display: inline-flex;
    border-radius: 33px;
    overflow: hidden;
    @include m {
      display: inline-block; } }
  &__control &__link {
    position: relative;
    flex: 0 0 316px;
    width: 316px;
    padding: 18px 20px;
    background: #5C64D3;
    text-align: center;
    @include t {
      flex: 0 0 280px;
      width: 280px; }
    @include m {
      display: inline-block;
      flex: 0 0 160px;
      width: 160px;
      padding: 10px; }
    @include nl {
      padding-left: 40px;
      @include m {
        padding-left: 20px; } }
    @include nf {
      padding-right: 40px;
      @include m {
        padding-right: 20px; } }
    &:nth-child(2) {
      &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 3px;
        height: 45px;
        border-radius: 3px;
        background: rgba($white, .6);
        @include m {
          height: 28px;
          width: 2px; } } } }
  &__link &__text {
    display: inline-block;
    position: relative;
    font-size: 24px;
    color: $white;
    transition: color .3s;
    @include m {
      font-size: 18px; }
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      transition: border-color .3s;
      @include t {
        top: 55%; } } }
  &__link:hover &__text {
    color: rgba($white, .6); }
  &__link_prev &__text {
    padding-left: 38px;
    @include m {
      padding-left: 20px; }
    &:before {
      left: 0;
      @include arr(10,16,$white,l);
      @include m {
        @include arr(8,12,$white,l); } } }
  &__link_prev:hover &__text:before {
    border-color: transparent rgba($white, .6) transparent transparent; }
  &__link_next &__text {
    padding-right: 38px;
    @include m {
      padding-right: 20px; }
    &:before {
      right: 0;
      @include arr(10,16,$white,r);
      @include m {
        @include arr(8,12,$white,r); } } }
  &__link_next:hover &__text:before {
    border-color: transparent transparent transparent rgba($white, .6); } }


.accord {
  &__item {
    @include nl {
      margin-bottom: 15px;
      @include m {
        margin-bottom: 10px; } } }
  &__item &__item {
    margin-left: 23px;
    @include m {
      margin-left: 15px; } }
  &__head {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 70px;
    padding: 0 60px 0 23px;
    border-radius: 15px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    font-size: 24px;
    color: $bl;
    cursor: pointer;
    transition: background .3s;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    @include t {
      min-height: 60px; }
    @include m {
      min-height: 45px;
      padding: 6px 35px 6px 15px;
      border-radius: 5px;
      font-size: 16px;
      line-height: 1.2; }
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      right: 27px;
      transform: translateY(-50%);
      @include arr(15,10,#5C64D3,b);
      transition: transform .3s;
      @include m {
        right: 15px;
        @include arr(10,6,#5C64D3,b); } }
    &:hover {
      background: $l-purple; } }
  &__item.active > &__head:before {
    transform: translateY(-50%) rotate(180deg); }
  &__counter {
    flex-shrink: 0;
    margin: 2px 0 0 20px;
    padding: 2px 11px;
    border-radius: 11px;
    background: $purple;
    font-size: 14px;
    color: $white;
    @include m {
      margin: 0 0 0 13px;
      padding: 2px 8px;
      border-radius: 5px;
      font-size: 10px; } }
  &__body {
    display: none;
    padding-top: 15px;
    @include m {
      padding-top: 10px; } }
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: -10px -5px 0;
    padding-bottom: 15px;
    @include m {
      margin: -6px -3px 0; }
    li {
      flex: 0 0 calc(calc((100%/12) - 10px);
      width: calc(calc((100%/12) - 10px);
      margin: 10px 5px 0;
      @include t {
        flex: 0 0 calc(calc((100%/10) - 10px);
        width: calc(calc((100%/10) - 10px); }
      @include m {
        flex: 0 0 calc(calc((100%/12) - 6px);
        width: calc(calc((100%/12) - 6px);
        margin: 6px 3px 0; }
      @include a {
        flex: 0 0 calc(calc((100%/7) - 6px);
        width: calc(calc((100%/7) - 6px); } } }
  &__list--row {
    .accord__number {
      justify-content: flex-start;
      height: auto;
      padding: 20px;
      @include t {
        padding-left: 30px; }
      @include m {
        padding-left: 15px; }
      @include a {
        padding-left: 15px; } }
    li {
      width: 100%;
      flex: 100%;
      @include t {
        width: 100%;
        flex: 100%; }
      @include m {
        width: 100%;
        flex: 100%; }
      @include a {
        width: 100%;
        flex: 100%; } } }
  &__number {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 75px;
    border: 2px solid $l-purple;
    border-radius: 15px;
    font-size: 24px;
    font-weight: 500;
    color: $bl;
    transition: all .3s;
    @include d {
      height: 70px; }
    @include t {
      height: 60px;
      font-size: 20px; }
    @include m {
      height: 40px;
      font-size: 16px; }
    &:hover {
      background: #5C64D3;
      border-color: #5C64D3;
      box-shadow: 0px 0px 5px rgba(100, 110, 227, 0.5);
      color: $white; } }
  &__link {
    display: flex;
    align-items: center;
    height: 70px;
    padding: 0 23px;
    border-radius: 15px;
    border: 2px solid $l-purple;
    font-size: 24px;
    color: $bl;
    transition: background .3s;
    @include t {
      height: 60px;
      font-size: 22px; }
    @include m {
      height: 45px;
      padding: 0 15px;
      font-size: 16px; }
    &:hover {
      background: $l-purple; }
    @include nl {
      margin-bottom: 15px;
      @include m {
        margin-bottom: 10px; } } } }

.share {
  display: flex;
  align-items: center;
  @include a {
    flex-wrap: wrap; }
  &__icon {
    margin-right: 25px;
    font-size: 0;
    @include m {
      margin-right: 15px; } }
  &__text {
    margin-right: 40px;
    font-size: 24px;
    font-weight: 800;
    @include m {
      margin-right: 20px;
      font-size: 20px; } }
  &__list {
    display: flex;
    align-items: center;
    @include a {
      flex: 0 0 100%;
      width: 100%;
      margin-top: 10px; } }
  &__link {
    font-size: 0;
    transition: transform .3s;
    &:hover {
      transform: translateY(-2px); }
    @include nl {
      margin-right: 25px;
      @include m {
        margin-right: 20px; } } }
  &__pic {
    @include a {
      max-height: 25px; } }
  &__link:last-child &__pic {
    @include a {
      max-height: 30px; } } }

.comment {
  position: relative;
  font-size: 0;
  &__textarea {
    width: 100%;
    height: 140px;
    padding: 17px 22px;
    border-radius: 5px;
    border: 1px solid #D1D1D9;
    resize: none;
    font-size: 24px;
    font-weight: 600;
    color: $bl;
    @include m {
      height: 100px;
      padding: 7px 12px;
      font-size: 16px; }
    @include placeholder {
      color: #D1D1D9; } }
  &__btn {
    position: absolute;
    right: 20px;
    bottom: -28px;
    width: 58px;
    height: 58px;
    padding-top: 3px;
    border-radius: 50%;
    background: #5C64D3;
    transition: background .3s;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    @include m {
      right: 15px;
      bottom: -22px;
      width: 44px;
      height: 44px; }
    .icon {
      font-size: 22px;
      fill: $white;
      @include m {
        font-size: 18px; } }
    &:hover {
      background: lighten(#5C64D3, 5); } } }

.mistake {
  padding: 85px 0;
  text-align: center;
  @include d {
    padding: 50px 0; }
  @include m {
    padding: 35px 0 50px; }
  &__info {
    margin-bottom: 50px;
    font-size: 36px;
    font-weight: 800;
    @include t {
      font-size: 30px; }
    @include m {
      margin-bottom: 35px;
      font-size: 24px;
      line-height: 1.2; } }
  &__preview {
    position: relative;
    margin-bottom: 35px;
    font-size: 0;
    @include m {
      margin-bottom: 25px; } }
  &__robot {
    position: relative;
    z-index: 2;
    @include m {
      width: 200px; } }
  &__close {
    position: absolute;
    top: -25px;
    left: 50%;
    transform: translateX(-50%);
    @include m {
      top: -35px;
      width: 260px; } }
  &__number {
    position: absolute;
    top: -120px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 550px;
    line-height: 1;
    font-weight: 800;
    color: #B9BEF5;
    @include d {
      top: -100px;
      font-size: 500px; }
    @include t {
      top: -30px;
      font-size: 400px; }
    @include m {
      top: 0px;
      font-size: 200px; } }
  &__back {
    display: inline-block;
    position: relative;
    padding-left: 15px;
    font-size: 14px;
    color: #D1D1D9;
    transition: color .3s;
    &:before {
      content: "";
      position: absolute;
      top: 55%;
      left: 0;
      transform: translateY(-50%);
      @include arr(5,7,#D1D1D9,l);
      transition: border-color .3s; }
    &:hover {
      color: $purple;
      &:before {
        border-color: transparent $purple transparent transparent; } } } }

.fancybox-button.fancybox-button--zoom {
  display: none!important; }


