.icon-search {
  width: 1em;
  height: 1em;
  fill: #fff;
}
.icon-telegram {
  width: 1.17em;
  height: 1em;
  fill: #fff;
}
